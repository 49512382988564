import React, { useState, useContext, useEffect, useRef } from "react";

// IMPORT recoil
import { useRecoilState } from 'recoil';
import {
  settingsAtomState,
  openInvoiceDialogAtomState,
  invoicesAtomState,
  clientsAtomState,
  invoicesFilteredAtomState,
} from "../_atoms";

// IMPORT global hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from '../shared/context/auth-context';
import { useAuth } from "../shared/hooks/auth-hook";
import { enqueueSnackbar } from 'notistack';

// IMPORT global functions
import {
  SET_VALUE,
  SET_VALUES,
  RETURN_DATE,
  RETURN_HOURS,
  RETURN_OVERDUE_DAYS,
} from '../_functions';

import {
  DB_GET_CLIENT,
  DB_GET_CLIENTS_LIST
} from '../_functions/DB_CLIENTS';

import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';


// IMPORT components
import { NumericFormat } from "react-number-format";

// IMPORT own components
import LogoMonogram from './../_assets/logo_monogram.svg';
import InvoiceLivePreview from "./components/InvoiceLivePreview";
import InvoiceNumberEdit from "./components/InvoiceNumberEdit";
import InvoiceNumber from "./invoice_dialog/components/InvoiceNumber";

import InvoiceAppBar from "./invoice_dialog/components/InvoiceAppBar";
import InvoiceAlerts from "./invoice_dialog/components/InvoiceAlerts";
import InvoiceBottomNavigation from "./invoice_dialog/components/InvoiceBottomNavigation";
import SentBlobsHistory from "./invoice_dialog/components/SentBlobsHistory";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import BlockIcon from '@material-ui/icons/Block';
import CustomEditor from "./components/CustomEditor";


// IMPORT Material-UI components
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Switch,
  Chip,
  Backdrop,
  BottomNavigation,
  AccordionDetails,
  Tooltip,
  Divider,
  Fab
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from "@material-ui/lab/Alert";

// IMPORT Material-UI icons from custom file
import {
  AddCircle as AddCircleIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  DeleteForever as DeleteForeverIcon,
  OpenWith as DragIcon,
  Edit as EditIcon,
  Gavel as GavelIcon,
  RecentActors as InvoicesIcon,
  Mail as MailIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
  Repeat as RepeatIcon,
  Block,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ScheduleIcon from '@material-ui/icons/Schedule';

// IMPORT date-fns utils and localization
import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// IMPORT own css
import "./InvoicesDIALOG.scss"

// IMPORT local utils and hooks
import { changeItem, deleteItem, addItem } from "./invoice_dialog/utils/ITEM";

import changeContinuousNumber from "./invoice_dialog/utils/CHANGE_CONTINUOUS_NUMBER";
import useInvoiceCalculations from "./invoice_dialog/utils/useInvoiceCalculations";

import useMount from './invoice_dialog/hooks/useMount';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: "5px",
      borderRadius: "5px"
    },
    margin: "5px",
    borderRadius: "5px"
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },

  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: '2px',
      borderColor: "#f0f3ff !important"
    },
  };
});

const InvoicesRecurringsDIALOG = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userId } = useAuth();
  const theme = useTheme();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [, setInvoicesFilteredAtom] = useRecoilState(invoicesFilteredAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [clientsAtom] = useRecoilState(clientsAtomState);

  // LOCAL STATE
  const [recurring, setRecurring] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [sendMode, setSendMode] = useState(false);
  const [openSentBlob, setOpenSentBlob] = useState(false);
  const [openInvoiceNumberEdit, setOpenInvoiceNumberEdit] = useState(false);
  const [addElementOpen, setAddElementOpen] = useState(false);
  const [, setShowGlobalDiscount] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    i: false,
    name: "",
    quantity: 1,
    price: 0,
    unit: "",
    vatPercent: "",
    discount: "none",
    discountPercent: 0,
    discountAbsolute: 0,
  });
  const [settings, setSettings] = useState(settingsAtom);
  const [values, setValues] = useState({
    additionalText: "",
    blob: "",
    clientData: "",
    discount: "none",
    discountAbsolute: "0",
    discountPercent: "0",
    discountValue: "0",
    discountedSubtotal: "0",
    discountedTotal: "0",
    dueDate: new Date().toString(),
    invoiceDate: new Date().toString(),
    invoiceNr: "",
    items: "",
    loading: true,
    payDate: new Date().toString(),
    payState: "notPayed",
    recurring: openInvoiceDialogAtom.recurring,
    recurringDueDays: openInvoiceDialogAtom.recurring !== true ? -1 : 0,
    recurringFrequency: "monthly",
    recurringStop: "indefinite",
    recurringStopDate: new Date().toString(),
    recurringStopNumber: 2,
    recurringRef: [],
    reminderRequestCharge: "0",
    sendDate: new Date().toString(),
    sendState: "notSent",
    sentBlobs: [],
    showAdditionalText: false,
    status: "draft",
    subtotal: "0",
    headline: "Rechnung",
    text: "Wir erlauben uns, Ihnen folgenden Betrag in Rechnung zu stellen und freuen uns auf weitere erfolgreiche Zusammenarbeit.",
    total: "0",
    type: "invoicerecurring",
    vatValues: "{}",
    cancelled: false,
    loading: true,
  })

  const [saveNewInvoiceSchema, setSaveNewInvoiceSchema] = useState(false)


  const [initialClientPicked, setInitialClientPicked] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientListFetched, setClientListFetched] = useState(false); // New state to track if clientList has been fetched
  const [loadingClients, setLoadingClients] = useState(false); // New state to track if clients are being fetched
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms)); // Helper function to show clientsList Loading Spinner for at least one second

  const [buttonLoadingAction, setButtonLoadingAction] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [disabledEditing, setDisabledEditing] = useState(false);
  const [newlyCreated, setNewlyCreated] = useState(false);
  const [newlyOpened, setNewlyOpened] = useState(true);

  // REF
  const textFieldRef = useRef(null);

  useMount(
    openInvoiceDialogAtom.invoiceId,
    auth,
    values,
    setValues,
    setSelectedClient,
    setClientList,
    setLoadingClients,
    setItems,
    "invoicerecurring"
  );

  useInvoiceCalculations(items, values, setValues, selectedClient);

  useEffect(() => {
    if (values.payState === "payed" || values.sendState === "scheduled" || values.cancelled) {
      setDisabledEditing(true)
    }
    if (values.type === "cancellation") {
      setValues({
        ...values,
        headline: "Stornorechnung",
        text: "Wir erlauben uns Ihnen diese Stornorechnung zu übermitteln. Der Betrag wird Ihnen demnächst rücküberwiesen.",
        showAdditionalText: true,
        additionalText: "Bitte den Rechnungsbetrag nicht überweisen. Der Betrag wird Ihnen in Kürze gut geschrieben."
      })
    } else if (values.type === "invoice") {
      setValues({
        ...values,
        headline: "Rechnung",
        text: "Wir erlauben uns, Ihnen folgenden Betrag in Rechnung zu stellen und freuen uns auf weitere erfolgreiche Zusammenarbeit.",
      })
    } else if (values.type === "reminder") {
      setValues({
        ...values,
        headline: "Zahlungserinnerung",
        text: "Wir möchten Sie freundlich daran erinnern, dass diese Rechnung noch nicht beglichen wurde. Wir ersuchen höflichst um Bezahlung des offenen Betrags. Sofern Sie die Zahlung zwischenzeitlich veranlasst haben, bitten wir Sie, dieses Schreiben als gegenstandslos zu betrachten.",
      })
    } else if (values.type === "reminderRequest") {
      setValues({
        ...values,
        headline: "Mahnung",
        text: "Für diese Rechnung konnten wir noch keinen Zahlungseingang feststellen. Wir ersuchen um Bezahlung des offenen Betrags. Sofern Sie die Zahlung zwischenzeitlich veranlasst haben, bitten wir Sie, dieses Schreiben als gegenstandslos zu betrachten.",
      })
    }
  }, [values.payState, values.sendState, values.cancelled, values.type]);

  // Fetch Clients List with loading spinner
  const fetchClientsList = async () => {
    setLoadingClients(true); // Start loading spinner
    try {
      // Call the DB_GET_CLIENTS_LIST function
      const clientsList = await DB_GET_CLIENTS_LIST(auth, sendRequest, delay);
      return clientsList; // Return the fetched clients list
    } catch (err) {
      console.log(err);
      return [];
    } finally {
      setLoadingClients(false); // Stop loading spinner once request is done
    }
  };

  // Handle client list fetch on dropdown open
  const handleClientListFetch = async () => {
    if (!clientListFetched) {
      const clientsList = await fetchClientsList();
      setClientList(clientsList);
      setClientListFetched(true); // Mark that the client list has been fetched
    }
  };

  useEffect(() => {
    if (selectedClient) {
      setValues(prevValues => ({ ...prevValues, clientData: selectedClient }));
    }
  }, [selectedClient]);

  // useEffect to trigger invoiceHandler after selectedClient is updated
  useEffect(() => {
    if (values.clientData !== "" && selectedClient !== null && !initialClientPicked) {
      const timer = setTimeout(() => {
        invoiceHandler(); // Execute the handler after the delay
        setInitialClientPicked(true)
      }, 0); // 0ms delay (you can adjust this time as needed)

      // Cleanup the timer when the effect re-runs or the component unmounts
      return () => clearTimeout(timer);
    }
  }, [values.clientData]); // The effect runs whenever selectedClient changes

  // CHANGE STATUS
  const handleChangeStatus = async (e) => {
    clearError();
    SET_VALUE(e, setValues);
  };

  // OPEN DATE PICKER
  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  // INVOICE NUMBER MENU DOTS
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openInvoiceNrMenuDots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // SAVE or UPDATE INVOICE
  const invoiceHandler = async () => {
    // Prepare FormData
    const formData = new FormData();

    // Loop through the values object and append each key-value pair to the formData, excluding "loading" and "sentBlobs"
    for (const key in values) {
      if (key !== "loading" && key !== "sentBlobs") {
        if (key === "clientData") {
          formData.append(key, JSON.stringify(values[key]));
        } else if (key === "items") {
          formData.append(key, JSON.stringify(items));
        } else if (typeof values[key] === 'object') {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          formData.append(key, values[key]);
        }
      }
    }

    try {
      // Determine the request method and URL based on whether invoiceId exists
      const url = openInvoiceDialogAtom.invoiceId
        ? `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/${openInvoiceDialogAtom.invoiceId}` // Update invoice
        : `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings`; // Create new invoice

      const method = openInvoiceDialogAtom.invoiceId ? "PATCH" : "POST";

      // Send the formData to the backend using sendRequest
      const responseData = await sendRequest(
        url,
        method,
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // If creating a new invoice, update settingsAtom and patch settings in DB
      if (!openInvoiceDialogAtom.invoiceId) {
        // Clone the current settingsAtom
        const updatedSettings = { ...settingsAtom };

        // Check if invoicesNumberItems is a string, and parse it if necessary
        let invoicesNumberItems = Array.isArray(updatedSettings.invoicesNumberItems)
          ? updatedSettings.invoicesNumberItems
          : JSON.parse(updatedSettings.invoicesNumberItems);

        // Deep clone invoicesNumberItems to avoid mutating the original object
        invoicesNumberItems = invoicesNumberItems.map(item => ({ ...item }));

        // Convert invoicesNumberItems back to string
        updatedSettings.invoicesNumberItems = JSON.stringify(invoicesNumberItems);

        // Update settingsAtom
        setSettingsAtom(updatedSettings);
        setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, invoiceId: responseData.invoice._id });
      }

      // If patching and saveNewInvoiceSchema is true, update settings with invoiceNr changes
      if (openInvoiceDialogAtom.invoiceId && saveNewInvoiceSchema) {
        const updatedSettings = {
          ...settingsAtom,
          invoicesNumberItems: JSON.stringify(values.invoiceNr ? JSON.parse(values.invoiceNr) : [])
        };
        // Call DB_PATCH_SETTINGS with the updatedSettings object
        await DB_PATCH_SETTINGS(updatedSettings, auth, sendRequest, enqueueSnackbar, t);
      }

      // Handle the response from the backend if needed
      (openInvoiceDialogAtom.invoiceId && !newlyOpened) && enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
      setNewlyOpened(false);
    } catch (error) {
      enqueueSnackbar(t("FIELDS.error"), { variant: "error" });
    }
  };


  const handlePauseSend = async () => {
    // Prepare FormData
    const formData = new FormData();
    formData.append("sendState", values.sentBlobs.length === 0 ? "notSent" : "sent");
    formData.append("sendDate", values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate);

    let patchUrl;
    switch (values.type) {
      case "invoice":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
        break;
      case "cancellation":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
        break;
      case "invoicerecurring":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/${openInvoiceDialogAtom.invoiceId}`;
        break;
      default:
        throw new Error("Unsupported fetch type");
    }

    try {
      // The URL for patching the specific invoice
      const url = patchUrl;

      // Send the formData to the backend using PATCH method
      const responseData = await sendRequest(
        url,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Handle the response if needed
      console.log("Send state updated successfully:", responseData);
    } catch (error) {
      console.error("Error updating send state:", error);
    }
  };

  const handleCancelSend = async () => {
    // Prepare FormData
    const formData = new FormData();
    formData.append("sendState", "sent");
    formData.append("sendDate", values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate);

    let patchUrl;
    switch (values.type) {
      case "invoice":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
        break;
      case "cancellation":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
        break;
      case "invoicerecurring":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/${openInvoiceDialogAtom.invoiceId}`;
        break;
      default:
        throw new Error("Unsupported fetch type");
    }

    try {
      // The URL for patching the specific invoice
      const url = patchUrl;

      // Send the formData to the backend using PATCH method
      const responseData = await sendRequest(
        url,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Handle the response if needed
      console.log("Send state updated successfully:", responseData);
    } catch (error) {
      console.error("Error updating send state:", error);
    }
  };

  return (
    <React.Fragment>
      <InvoiceAppBar
        setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
        openInvoiceDialogAtom={openInvoiceDialogAtom}
        selectedClient={selectedClient}
        isLoading={isLoading}
        setSendMode={setSendMode}
        setOpen={setOpen}
        invoiceHandler={invoiceHandler}
      />

      <InvoiceAlerts
        selectedClient={selectedClient}
        settings={settings}
        values={values}
        setValues={setValues}
        openInvoiceDialogAtom={openInvoiceDialogAtom}
        // invoiceHandler={invoiceHandler} 
        setShowConfirmModal={setShowConfirmModal}
      />

      <InvoiceBottomNavigation
        selectedClient={selectedClient}
        settings={settings}
        settingsAtom={settingsAtom}
        setSettingsAtom={setSettingsAtom}
        values={values}
        isLoading={isLoading}
        buttonLoadingAction={buttonLoadingAction}
        invoiceHandler={invoiceHandler}
        setButtonLoadingAction={setButtonLoadingAction}
        openInvoiceDialogAtom={openInvoiceDialogAtom}
        setOpen={setOpen}
        setSendMode={setSendMode}
        saveNewInvoiceSchema={saveNewInvoiceSchema}
      />

      <React.Fragment>
        <div>
          <div className="invoice-edit">
            <div className={classes.toolbar} />
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
                {!values.loading &&
                <Box display="flex"
                flexDirection="column"
                margin="0 25px"
                className="h-gap--15"
                style={{ minHeight: "100vh", margin: 0 }}
              >

                  <Grid container item xs={12} lg={12} style={{ paddingTop: 30 }}>
                    {(newlyCreated || openInvoiceDialogAtom.duplicate === true) &&
                      <Grid container>
                        <Alert
                          severity="success"
                          style={{
                            color: "inherit",
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 0,
                            display: "flex",
                            alignItems: "center",
                            marginTop: 10
                          }}
                        >
                          <span
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              fontWeight: 500,
                            }}
                          >
                            {(openInvoiceDialogAtom.duplicate === true && openInvoiceDialogAtom.duplicate !== undefined) ? "Duplikat als Entwurf erstellt!" : "Entwurf erstellt!"}
                          </span>
                        </Alert>
                      </Grid>
                    }


                    {/* SCHEDULED */}
                    {(values.recurring && openInvoiceDialogAtom.invoiceId !== "") &&
                      <Grid container style={{ marginTop: 20 }}>
                        <Chip
                          icon={values.recurringRef.length > 0 ? <PlaylistAddCheckIcon style={{ color: "white" }} /> : <SubjectIcon style={{ color: "white" }} />}
                          label={`${values.recurringRef.length} ${values.recurringRef.length === 1 ? "Rechnung" : "Rechnungen"} gesendet`}
                          style={{
                            backgroundColor:values.recurringRef.length > 0 ? theme.palette.primary.main : "#c4c7df",
                            color: "white",
                            fontSize: '12px',
                            fontWeight: "bold",
                            borderRadius: 6,
                          }}
                        />
                      </Grid>
                    }
                    {(values.sendState === "scheduled") && (
                      <>
                        <Alert
                          severity="warning"
                          icon={
                            <ScheduleIcon
                              style={{
                                color: "#ff9800",
                                fontSize: 26,
                                marginTop: -2,
                              }}
                            />
                          }
                          style={{
                            color: "inherit",
                            fontWeight: 500,
                            paddingTop: 15,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: 20,
                            display: "flex",
                            alignItems: "flex-start",
                            marginTop: 10,
                            background: "white",
                            boxShadow: "0 24px 64px rgba(84, 94, 165, 0.1019607843)",
                            borderRadius: 10,
                            width: "100%"
                          }}
                        >
                          <span
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              fontWeight: 500,
                            }}
                          >
                            Nächster Versand: {`${RETURN_DATE(values.sendDate)} | ${RETURN_HOURS(values.sendDate)}`}
                            {!values.cancelled && (
                              <span style={{ fontSize: 11 }}>
                                <br />
                                <div style={{ marginTop: 15, fontWeight: 300 }}>Versand pausieren um diese Rechnung zu bearbeiten und/oder später wieder zu versenden.</div>
                                <Button
                                  onClick={(e) => {
                                    handlePauseSend();
                                    setShowConfirmModal(true);
                                    setValues({
                                      ...values,
                                      sendState: values.sentBlobs.length === 0 ? "notSent" : "sent",
                                      sendDate: values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate
                                    });
                                    setDisabledEditing(false);
                                  }}
                                  variant="outlined"
                                  size="small"
                                  style={{
                                    color: theme.palette.primary.main,
                                    marginTop: 5,
                                  }}
                                >
                                  <PauseCircleFilledIcon style={{ marginRight: 5 }} /> Versand Pausieren
                                </Button>

                                <br />
                                <div style={{ marginTop: 15, fontWeight: 300 }}>Versand beenden, falls diese Rechnung nicht mehr versendet werden soll.</div>
                                <Button
                                  onClick={(e) => {
                                    handleCancelSend();
                                    setShowConfirmModal(true);
                                    setValues({
                                      ...values,
                                      sendState: "sent",
                                      sendDate: values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate
                                    });
                                    setDisabledEditing(false);
                                  }}
                                  variant="outlined"
                                  size="small"
                                  style={{
                                    backgroundColor: "white",
                                    color: theme.palette.primary.main,
                                    marginTop: 5,
                                  }}
                                >
                                  <BlockIcon style={{ marginRight: 5 }} /> Versand beenden
                                </Button>
                              </span>
                            )}
                          </span>
                        </Alert>
                      </>
                    )}

                    
                  </Grid>


                  {/* GENERAL INPUTS */}
                  <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          item
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                      <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                        {!values.recurring &&
                          <>
                            <Grid item xs={12} md={3} className="datepicker" style={{ marginBottom: 10 }}>
                              <KeyboardDatePicker
                                disabled={isLoading || values.recurring || disabledEditing}
                                fullWidth
                                inputVariant="filled"
                                id="invoiceDate"
                                format="dd.MM.yyyy"
                                label={(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Rechnungsdatum" : "Datum"}
                                value={values.invoiceDate}
                                onChange={(date) => {
                                  const invoiceNumberItems = typeof values.invoiceNr === "string" ? JSON.parse(values.invoiceNr) : values.invoiceNr;

                                  // Clone the array to avoid direct mutation
                                  const updatedInvoiceNumberItems = [...invoiceNumberItems];

                                  // Update Year
                                  const yearIndex = updatedInvoiceNumberItems.findIndex(item => item.type === "year");
                                  if (yearIndex !== -1) {
                                    updatedInvoiceNumberItems[yearIndex].value = new Date(date).getFullYear();
                                  }

                                  // Update Month
                                  const monthIndex = updatedInvoiceNumberItems.findIndex(item => item.type === "month");
                                  if (monthIndex !== -1) {
                                    updatedInvoiceNumberItems[monthIndex].value = new Date(date).getMonth() + 1; // Months are 0-indexed
                                  }

                                  // Update Day
                                  const dayIndex = updatedInvoiceNumberItems.findIndex(item => item.type === "day");
                                  if (dayIndex !== -1) {
                                    updatedInvoiceNumberItems[dayIndex].value = new Date(date).getDate();
                                  }

                                  // Now, update the values with the new invoice date and updated invoice number
                                  setValues({
                                    ...values,
                                    invoiceDate: date.toString(),
                                    invoiceNr: JSON.stringify(updatedInvoiceNumberItems),  // Convert the updated array back to a string
                                    dueDate: values.recurringDueDays !== -1
                                      ? (new Date(new Date(date.toString()).setDate(new Date(date.toString()).getDate() + values.recurringDueDays))).toString()
                                      : values.dueDate,
                                  });
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                okLabel="OK"
                                cancelLabel="Abbrechen"
                                open={openPicker}
                                onOpen={() => setOpenPicker(true)}
                                onClose={() => setOpenPicker(false)}
                              />

                            </Grid>

                            <Grid item xs={12} md={3} className="datepicker" style={{ marginBottom: 10 }}>
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel id="document-type-label">Typ</InputLabel>
                                <Select
                                  labelId="document-type-label"
                                  value={values.type}
                                  onChange={(e) => setValues({ ...values, type: e.target.value })}
                                  label="Typ"
                                >
                                  <MenuItem value="invoice">Rechnung</MenuItem>
                                  <MenuItem value="reminder">Zahlungserinnerung</MenuItem>
                                  <MenuItem value="reminderRequest">Mahnung</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        }
                      </MuiPickersUtilsProvider>

                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          disabled={isLoading || disabledEditing || values.type === "cancellation"}
                          style={{ width: "100%" }}
                          required
                          id="client"
                          onOpen={handleClientListFetch} // Fetch clientList when dropdown is opened
                          onChange={async (event, value) => {
                            if (value) {
                              if (openInvoiceDialogAtom.invoiceId === "") {
                                setNewlyCreated(true)
                              }
                              try {
                                const clientDetails = await DB_GET_CLIENT(value.id, auth, sendRequest);
                                if (clientDetails) {
                                  setSelectedClient(clientDetails);
                                  // setValues(prevValues => ({ ...prevValues, clientData: clientDetails }));
                                }
                              } catch (error) {
                                console.error("Error fetching client details:", error);
                              }
                            }
                          }}
                          disableClearable
                          defaultValue={selectedClient}
                          value={selectedClient || null}
                          getOptionSelected={(option, value) => option.id === value.id}
                          options={clientList}
                          classes={{
                            option: classes.option,
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.company}
                          loading={loadingClients} // Pass the loading state to display the loading text
                          loadingText="Liste wird geladen ..." // Custom loading text while fetching
                          noOptionsText="Nichts gefunden" // Default text when no options are available
                          // Display the loading spinner if the clientList is still being fetched
                          renderOption={(option) => (
                            <>
                              {option.logo !== "" && option.logo !== undefined ? (
                                <div style={{ width: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                  <img
                                    src={option.logo}
                                    alt="logo"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      marginRight: "10px",
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)"
                                    }}
                                  />
                                </div>
                              ) : (
                                <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", marginRight: "10px", opacity: 0.2 }} />
                              )}
                              {option.company}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Kund:in"
                              placeholder="Auswählen"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                                // style: {
                                //   background: 'rgb(240, 243, 255)',
                                //   padding: "2px 5px",
                                //   marginLeft: "-2px",
                                //   borderRadius: "6px"
                                // }
                              }}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    {selectedClient && (selectedClient.logo !== "" && selectedClient.logo !== undefined) ? (
                                      <div style={{ width: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                        <img
                                          src={selectedClient.logo}
                                          alt="logo"
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            marginRight: "10px",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)"
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", marginRight: "10px", opacity: 0.2 }} />
                                    )}
                                  </>
                                ),
                                endAdornment: (
                                  <>
                                    {params.InputProps.endAdornment}
                                    {loadingClients ? <CircularProgress color="inherit" size={20} /> : null}
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {selectedClient && values.clientData !== "" && openInvoiceDialogAtom.invoiceId !== "" &&
                        <Grid container item spacing={2}>
                          <Grid container item xs={12} md={12} style={{marginTop: 10}}>
                          <InvoiceNumber
                            values={values}
                            isLoading={isLoading}
                            handleOpenPicker={handleOpenPicker}
                            changeContinuousNumber={changeContinuousNumber}
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            openInvoiceNrMenuDots={openInvoiceNrMenuDots}
                            handleClose={handleClose}
                            addElementOpen={addElementOpen}
                            setAddElementOpen={setAddElementOpen}
                            setValues={setValues}
                            setOpenInvoiceNumberEdit={setOpenInvoiceNumberEdit}
                            openInvoiceDialogAtom={openInvoiceDialogAtom}
                            saveNewInvoiceSchema={saveNewInvoiceSchema}
                            setSaveNewInvoiceSchema={setSaveNewInvoiceSchema}
                            disabledEditing={disabledEditing}
                          />

                          <InvoiceNumberEdit
                            openInvoiceNumberEdit={openInvoiceNumberEdit}
                            setOpenInvoiceNumberEdit={setOpenInvoiceNumberEdit}
                            selectedClient={selectedClient}
                            values={values}
                            setValues={setValues}
                            settingsAtom={settingsAtom}
                            recurring={true}
                          />
                          </Grid>

                          <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                          
                            {!values.type === "cancellation" &&
                              <Grid container item xs={12} md={3}>
                                <FormControl disabled={isLoading || disabledEditing} variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                  <InputLabel id="recurringDueDays">{!values.recurring ? "Fälligkeit" : "Fälligkeit"}</InputLabel>
                                  <Select
                                    labelId="recurringDueDays"
                                    id="recurringDueDays"
                                    name="recurringDueDays"
                                    value={values.recurringDueDays} // Sofort corresponds to 0 days
                                    label="Fälligkeit"
                                    onChange={(e) => setValues({
                                      ...values,
                                      recurringDueDays: e.target.value,
                                      dueDate: e.target.value !== - 1 ? (new Date(new Date(values.invoiceDate).setDate(new Date(values.invoiceDate).getDate() + e.target.value))).toString() : values.dueDate,
                                    })}
                                  >
                                    {!values.recurring && <MenuItem value={-1}>Bestimmtes Datum definieren</MenuItem>}
                                    <MenuItem value={0}>Sofort (Fälligkeitsdatum entspricht Rechnungsdatum)</MenuItem>
                                    <Divider />
                                    <MenuItem value={1}>1 Tag</MenuItem>
                                    <MenuItem value={2}>2 Tage</MenuItem>
                                    <MenuItem value={3}>3 Tage</MenuItem>
                                    <MenuItem value={4}>4 Tage</MenuItem>
                                    <MenuItem value={5}>5 Tage</MenuItem>
                                    <MenuItem value={6}>6 Tage</MenuItem>
                                    <Divider />
                                    <MenuItem value={7}>1 Woche</MenuItem>
                                    <MenuItem value={14}>2 Wochen</MenuItem>
                                    <MenuItem value={21}>3 Wochen</MenuItem>
                                    <Divider />
                                    <MenuItem value={30}>1 Monat (30 Tage)</MenuItem>
                                    <MenuItem value={60}>2 Monate (60 Tage)</MenuItem>
                                    <MenuItem value={90}>3 Monate (90 Tage)</MenuItem>
                                    <MenuItem value={120}>4 Monate (120 Tage)</MenuItem>
                                    <MenuItem value={150}>5 Monate (150 Tage)</MenuItem>
                                    <MenuItem value={180}>6 Monate (180 Tage)</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            }

                            {(!values.recurring && !values.type === "cancellation") &&
                              <Grid item xs={12} md={3} className="datepicker">
                                <KeyboardDatePicker
                                  disabled={disabledEditing || isLoading || values.recurringDueDays !== -1} // -1 meaning manually select date
                                  fullWidth
                                  inputVariant="filled"
                                  id="dueDate"
                                  format="dd.MM.yyyy"
                                  label="Fälligkeitsdatum"
                                  value={values.dueDate}
                                  onChange={(date) =>
                                    setValues({
                                      ...values,
                                      dueDate: date.toString(),
                                    })
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  okLabel="OK"
                                  cancelLabel="Abbrechen"
                                />
                              </Grid>
                            }

                          </MuiPickersUtilsProvider>

                          {values.recurring &&
                            <Grid container item xs={12} md={3}>
                              <FormControl disabled={isLoading || disabledEditing} variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                <InputLabel id="recurringFrequency">Intervall</InputLabel>
                                <Select
                                  labelId="recurringFrequency"
                                  id="recurringFrequency"
                                  name="recurringFrequency"
                                  value={values.recurringFrequency}
                                  onChange={(e) => setValues({ ...values, recurringFrequency: e.target.value })}
                                  label="Versendungsintervall"
                                  fullWidth
                                >
                                  <MenuItem value={"minutely"}>Minütlich</MenuItem>
                                  <MenuItem value={"5minutely"}>5Minütlich</MenuItem>
                                  <MenuItem value={"daily"}>Täglich</MenuItem>
                                  <MenuItem value={"monthly"}>Monatlich</MenuItem>
                                  <MenuItem value={"bimonthly"}>Alle 2 Monate</MenuItem>
                                  <MenuItem value={"quarterly"}>Vierteljährlich (quartalsmäßig)</MenuItem>
                                  <MenuItem value={"biannually"}>Halbjährlich</MenuItem>
                                  <MenuItem value={"annually"}>Jährlich</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          }

                          {values.recurring &&
                            <Grid container item xs={12} md={3}>
                              <FormControl disabled={isLoading || disabledEditing} variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                <InputLabel id="recurringStop">Stoppen</InputLabel>
                                <Select
                                  labelId="recurringStop"
                                  id="recurringStop"
                                  name="recurringStop"
                                  defaultValue="indefinite"
                                  value={values.recurringStop}
                                  onChange={(e) => setValues({ ...values, recurringStop: e.target.value })}
                                  label="Versand stoppen"
                                  fullWidth
                                >
                                  <MenuItem value={"indefinite"}>Manuell</MenuItem>
                                  {/* <MenuItem value={"maxdate"}>An einem bestimmten Datum</MenuItem> */}
                                  <MenuItem value={"maxcount"}>Wenn eine bestimmte Anzahl an Einzelrechnungen erreicht wurde</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          }

                          {(values.recurring && values.recurringStop === "maxdate") &&
                            <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                              <Grid item xs={12} md={3} className="datepicker" >
                                <KeyboardDatePicker
                                  disabled={isLoading || disabledEditing}
                                  fullWidth
                                  inputVariant="filled"
                                  id="recurringStopDate"
                                  format="dd.MM.yyyy"
                                  label="Enddatum des Versands"
                                  value={values.recurringStopDate}
                                  onChange={(date) =>
                                    setValues({
                                      ...values,
                                      recurringStopDate: date.toString(),
                                    })
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  okLabel="OK"
                                  cancelLabel="Abbrechen"
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          }

                          {(values.recurring && values.recurringStop === "maxcount") &&
                            <Grid container item xs={12} md={3}>
                              <FormControl variant="outlined" fullWidth disabled={isLoading || disabledEditing}>
                                <TextField
                                  disabled={isLoading || disabledEditing}
                                  id="recurringStopNumber"
                                  name="recurringStopNumber"
                                  label="Anzahl Einzelrechnungen"
                                  variant="outlined"
                                  value={values.recurringStopNumber}
                                  onChange={(e) => {
                                    setValues({ ...values, recurringStopNumber: e.target.value, isEdited: true });
                                  }}
                                  error={
                                    values.isEdited &&
                                    (parseInt(values.recurringStopNumber, 10) <= 1 ||
                                      parseInt(values.recurringStopNumber, 10) <= values.recurringRef.length)
                                  }
                                  type="number"
                                  helperText={
                                    values.isEdited &&
                                    (parseInt(values.recurringStopNumber, 10) <= 1
                                      ? 'Bitte geben Sie eine Zahl größer als 1 ein'
                                      : parseInt(values.recurringStopNumber, 10) <= values.recurringRef.length
                                        ? `Die Anzahl muss höher als die Anzahl der bereits versendeten Rechnungen sein (Bereits versendet: ${values.recurringRef.length})`
                                        : '')
                                  }
                                  FormHelperTextProps={{
                                    style: { color: 'red' },
                                  }}
                                  InputProps={{
                                    inputProps: { style: { textAlign: 'center' } }, // Inline centering
                                  }}
                                />


                              </FormControl>
                            </Grid>
                          }
                        </Grid>
                      }
                    </Grid>
                  </Box>

                  {/* Headline and Introtext */}
                  {selectedClient && values.clientData !== "" && openInvoiceDialogAtom.invoiceId !== "" &&
                      <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        item
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >
                        {selectedClient &&
                          <Grid item xs={12} style={{ marginBottom: "20px" }}>
                            <TextField
                              disabled={isLoading || disabledEditing}
                              multiline
                              autoComplete="off"
                              // defaultValue={values.type === "cancellation" ? "Stornorechnung" : (values.headline || "Rechnung")}
                              value={values.headline}
                              id="headline"
                              label={`Überschrift`}
                              variant="outlined"
                              type="textarea"
                              onChange={handleChangeStatus}
                              fullWidth
                            />
                          </Grid>
                        }

                        {selectedClient &&
                          <Grid item xs={12}>
                            <TextField
                              disabled={isLoading || disabledEditing}
                              multiline
                              autoComplete="off"
                              value={values.text}
                              id="text"
                              label={`Einleitungstext`}
                              variant="outlined"
                              type="textarea"
                              onChange={handleChangeStatus}
                              fullWidth
                            />
                          </Grid>
                        }
                      </Grid>
                    </Box>
                  }

                    {/* Items */}
                    {selectedClient && values.clientData !== "" && openInvoiceDialogAtom.invoiceId !== "" &&
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          item
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          <Grid container item lg={12} spacing={1} >
                            <Grid container item spacing={1}>
                              <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
                                <Grid item xs={12}>
                                  <div className={classes.root}>
                                    {items.map((item, i) => (
                                      <Accordion
                                        square
                                        expanded={isLoading ? false : currentItem.i === i + 1}
                                        className="accordion"
                                        disableGutters={true}
                                        disabled={isLoading}
                                        TransitionProps={{ timeout: { appear: 1, enter: 1, exit: 4 } }}
                                        style={{
                                          border: i + 1 === currentItem.i ? "1px solid #cfd1e5" : "none", // Apply border if this accordion is open
                                          borderRadius: "5px", // Optional: Keep a consistent style
                                        }}
                                      >
                                        <AccordionSummary
                                          key={i + 1}
                                          onClick={() => {
                                            console.log(currentItem)
                                            console.log(item.discountedTotal)
                                            setCurrentItem({
                                              ...currentItem,
                                              discount: item.discount,
                                              discountAbsolute: item.discountAbsolute,
                                              discountPercent: item.discountPercent,
                                              discountedTotal: item.discountedTotal,
                                              i: currentItem.i !== i + 1 ? i + 1 : false,
                                            });
                                          }}
                                          expandIcon={<EditIcon className="edit-icon" />}
                                          style={{ borderBottom: "none" }}
                                          aria-controls={item.name}
                                          id={item.name}
                                        >
                                          <Grid container item xs={12} spacing={1} style={{ alignItems: "center" }}>
                                            <Grid container item xs={12} md={11} spacing={1}>
                                              <Grid container item xs={9} md={9} alignItems="center">
                                                <Typography style={{ fontSize: "13px", fontWeight: 500 }}>
                                                  {item.name}
                                                </Typography>
                                              </Grid>
                                              <Grid container item xs={12} md={3} justifyContent="flex-end" alignItems="center" className="price">
                                                <Typography style={{ fontWeight: 500, fontSize: "13px" }}>
                                                  {parseFloat(item.discountedTotal ? item.discountedTotal : 0).toLocaleString("de-DE", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })} € (netto)
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </AccordionSummary>

                                        {currentItem.i === i + 1 && (
                                          <AccordionDetails>
                                            <Grid container item xs={12}>
                                              <Grid container item lg={12} spacing={2}>
                                                <Grid container item lg={12} alignItems="center">
                                                  {/* Leistung Input */}
                                                  <Grid item xs={12}>
                                                    <TextField
                                                      disabled={disabledEditing}
                                                      autoComplete="off"
                                                      className="input-white"
                                                      id="name"
                                                      label="Leistung/Produkt"
                                                      variant="outlined"
                                                      type="text"
                                                      value={item.name}
                                                      onChange={(e) => {
                                                        const updatedItems = [...items];
                                                        updatedItems[i] = {
                                                          ...updatedItems[i],
                                                          name: e.target.value,
                                                        };
                                                        setItems(updatedItems);
                                                      }}
                                                      fullWidth
                                                    />
                                                  </Grid>

                                                  {/* Switch for Details */}
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    style={{ paddingTop: 10 }}
                                                  >
                                                    <FormControlLabel
                                                      disabled={disabledEditing}
                                                      control={
                                                        <Switch
                                                          checked={item.showDetails || false}
                                                          onChange={(e, checked) => {
                                                            const updatedItems = [...items];
                                                            updatedItems[i] = {
                                                              ...updatedItems[i],
                                                              showDetails: checked,
                                                            };
                                                            setItems(updatedItems);
                                                          }}
                                                          color="primary"
                                                        />
                                                      }
                                                      label={
                                                        <Typography variant="body2">
                                                          Details zur Leistung / zum Produkt einblenden
                                                        </Typography>
                                                      }
                                                    // No additional onClick to avoid interference
                                                    />
                                                  </Grid>
                                                </Grid>

                                                {item.showDetails && (
                                                  <Grid container item lg={12} style={{ marginTop: -10, paddingLeft: 8, paddingBottom: 20 }}>

                                                    <CustomEditor
                                                      details={item.details || ""}
                                                      onUpdateDetails={(newDetails) => {
                                                        const updatedItems = [...items];
                                                        updatedItems[i] = {
                                                          ...updatedItems[i],
                                                          details: newDetails,
                                                        };
                                                        setItems(updatedItems);
                                                      }}
                                                      disabled={disabledEditing}
                                                    />
                                                  </Grid>
                                                )}

                                                {/* Preis Input */}
                                                <Grid container item md={6} lg={2}>
                                                  <NumericFormat
                                                    disabled={disabledEditing}
                                                    fullWidth
                                                    className="input-white"
                                                    id="price"
                                                    label="Einzelpreis (netto)"
                                                    variant="outlined"
                                                    value={item.price}
                                                    prefix="€ "
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    fixedDecimalScale // Ensures decimals are always shown
                                                    decimalScale={2}
                                                    valueIsNumericString
                                                    customInput={TextField}
                                                    onValueChange={({ value: v }) => {
                                                      const updatedItems = [...items];
                                                      updatedItems[i] = {
                                                        ...updatedItems[i],
                                                        price: v,
                                                        total: (v * item.quantity).toFixed(2), // Update total
                                                        discountValue: currentItem.discount === "percent" ? (v * item.quantity).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue,
                                                        discountedTotal: parseFloat(((v * item.quantity) - parseFloat(currentItem.discount === "percent" ? (v * item.quantity).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue)).toFixed(2))
                                                      };
                                                      setItems(updatedItems);
                                                      setCurrentItem({
                                                        ...currentItem,
                                                        price: v,
                                                        total: (v * item.quantity).toFixed(2), // Update total
                                                        discountValue: currentItem.discount === "percent" ? (v * item.quantity).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue,
                                                        discountedTotal: parseFloat(((v * item.quantity) - parseFloat(currentItem.discount === "percent" ? (v * item.quantity).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue)).toFixed(2))
                                                      })
                                                    }}
                                                  />
                                                </Grid>

                                                {/* Menge Input */}
                                                <Grid container item md={6} lg={2}>
                                                  <NumericFormat
                                                    disabled={disabledEditing}
                                                    fullWidth
                                                    className="input-white"
                                                    id="quantity"
                                                    label="Menge"
                                                    variant="outlined"
                                                    value={item.quantity}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    valueIsNumericString
                                                    customInput={TextField}
                                                    onValueChange={({ value: v }) => {
                                                      const updatedItems = [...items];
                                                      const newPrice = parseFloat(v || 0);
                                                      const quantity = parseFloat(updatedItems[i].quantity || 0);
                                                      updatedItems[i] = {
                                                        ...updatedItems[i],
                                                        quantity: v,
                                                        total: (item.price * v).toFixed(2), // Update total
                                                        discountValue: currentItem.discount === "percent" ? (item.price * v).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue,
                                                        discountedTotal: parseFloat(((item.price * v) - parseFloat(currentItem.discount === "percent" ? (item.price * v).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue)).toFixed(2))
                                                      };
                                                      setItems(updatedItems);
                                                      setCurrentItem({
                                                        ...currentItem,
                                                        quantity: v,
                                                        total: (item.price * v).toFixed(2), // Update total
                                                        discountValue: currentItem.discount === "percent" ? (item.price * v).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue,
                                                        discountedTotal: parseFloat(((item.price * v) - parseFloat(currentItem.discount === "percent" ? (item.price * v).toFixed(2) * parseFloat(item.discountPercent)/100 : item.discountValue)).toFixed(2))
                                                      })
                                                    }}
                                                  />
                                                </Grid>

                                                {/* Einheit Input */}
                                                <Grid container item md={6} lg={2}>
                                                  <TextField
                                                    disabled={disabledEditing}
                                                    id="unit"
                                                    label="Einheit"
                                                    variant="outlined"
                                                    type="text"
                                                    placeholder="h/kg/cm etc."
                                                    value={item.unit}
                                                    onChange={(e) => {
                                                      const updatedItems = [...items];
                                                      updatedItems[i] = {
                                                        ...updatedItems[i],
                                                        unit: e.target.value,
                                                        total: (item.price * item.quantity).toFixed(2), // Update total
                                                      };
                                                      setItems(updatedItems);
                                                    }}
                                                    fullWidth
                                                  />
                                                </Grid>

                                                {/* USt. Input */}
                                                <Grid container item md={6} lg={1}>
                                                  <NumericFormat
                                                    disabled={disabledEditing}
                                                    fullWidth
                                                    className="input-white"
                                                    id="vatPercent"
                                                    label="USt."
                                                    variant="outlined"
                                                    value={item.vatPercent}
                                                    decimalScale={0}
                                                    valueIsNumericString
                                                    customInput={TextField}
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <span>%</span>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    onValueChange={({ value: v }) => {
                                                      const updatedItems = [...items];
                                                      updatedItems[i] = {
                                                        ...updatedItems[i],
                                                        vatPercent: v,
                                                      };
                                                      setItems(updatedItems);
                                                    }}
                                                  />
                                                </Grid>

                                                {currentItem.discount === "none" &&
                                                  <Grid container item md={6} lg={3}>
                                                    <FormControl variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                                      <InputLabel id="discount">Rabatt</InputLabel>
                                                      <Select
                                                        disabled={disabledEditing}
                                                        labelId="discount"
                                                        id="discount"
                                                        name="discount"
                                                        value={currentItem.discount}
                                                        onChange={(e) => {
                                                          const updatedItems = [...items];
                                                          updatedItems[i] = {
                                                            ...updatedItems[i],
                                                            discount: e.target.value,
                                                            discountValue: 0,
                                                            discountAbsolute: 0,
                                                            discountPercent: 0,
                                                          };
                                                          setItems(updatedItems);
                                                          setCurrentItem({
                                                            ...currentItem,
                                                            discount: e.target.value,
                                                            discountValue: 0,
                                                            discountAbsolute: 0,
                                                            discountPercent: 0,
                                                          });
                                                          console.log(currentItem)
                                                          // changeItem(e, currentItem.i, items, setItems);
                                                        }
                                                        }
                                                        label="Rabatt"
                                                        fullWidth
                                                      >
                                                        <MenuItem value={"none"}>Kein Rabatt</MenuItem>
                                                        <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                                        <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                                      </Select>
                                                    </FormControl>
                                                  </Grid>
                                                }

                                                {currentItem.discount === "absolute" &&
                                                  <Grid container item md={6} lg={3}>
                                                    <NumericFormat
                                                      style={{ width: "100%", minWidth: "100%" }}
                                                      required
                                                      id="discountAbsolute"
                                                      label="Rabatt in €"
                                                      variant="outlined"
                                                      value={currentItem.discountAbsolute}
                                                      prefix="€ "
                                                      thousandSeparator={"."}
                                                      decimalSeparator={","}
                                                      decimalScale={2}
                                                      valueIsNumericString
                                                      fixedDecimalScale
                                                      customInput={TextField}
                                                      InputLabelProps={{
                                                        classes: {
                                                          root: classes.cssLabel,
                                                          focused: classes.cssFocused,
                                                        },
                                                      }}

                                                      InputProps={{
                                                        classes: {
                                                          root: classes.cssOutlinedInput,
                                                          focused: classes.cssFocused,
                                                          notchedOutline: classes.notchedOutline,
                                                        },
                                                        endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                                          <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                                            <IconButton
                                                              style={{ marginLeft: "5px" }}
                                                              aria-label="delete discount"
                                                              onClick={(e) => {
                                                                const updatedItems = [...items];
                                                                updatedItems[i] = {
                                                                  ...updatedItems[i],
                                                                  discount: "none",
                                                                  discountAbsolute: 0,
                                                                  discountPercent: 0,
                                                                  discountValue: 0,
                                                                };
                                                                setItems(updatedItems);

                                                                setCurrentItem({
                                                                  ...currentItem,
                                                                  discount: "none",
                                                                  discountAbsolute: 0,
                                                                  discountPercent: 0,
                                                                  discountValue: 0,
                                                                });
                                                                changeItem(
                                                                  {
                                                                    target: {
                                                                      value: "none", id: "discount",
                                                                    },
                                                                  },
                                                                  currentItem.i,
                                                                  items,
                                                                  setItems
                                                                );
                                                                
                                                              }}
                                                            >
                                                              <DeleteForeverIcon fontSize="small" />
                                                            </IconButton>
                                                          </div>
                                                        </InputAdornment>
                                                      }}
                                                      onValueChange={({ value: v }) =>  {
                                                        setCurrentItem({
                                                          ...currentItem,
                                                          discountAbsolute: v,
                                                        })
                                                        changeItem(
                                                          {
                                                            target: { value: v, id: "discountAbsolute" },
                                                          },
                                                          currentItem.i,
                                                          items,
                                                          setItems
                                                        )
                                                        }
                                                      }
                                                      onBlur={(e) => {
                                                        changeItem(
                                                          {
                                                            target: { value: currentItem.discountAbsolute, id: "discountAbsolute" },
                                                          },
                                                          currentItem.i,
                                                          items,
                                                          setItems
                                                        )
                                                      }}
                                                    />
                                                  </Grid>
                                                }

                                                {currentItem.discount === "percent" && (
                                                  <Grid container item md={6} lg={3}>
                                                    <NumericFormat
                                                      style={{ width: "100%", minWidth: "100%" }}
                                                      required
                                                      id="discountPercent"
                                                      label="Rabatt in %"
                                                      variant="outlined"
                                                      value={currentItem.discountPercent}
                                                      decimalSeparator=","
                                                      thousandSeparator="."
                                                      decimalScale={2} // Allow up to 2 decimal places
                                                      valueIsNumericString
                                                      fixedDecimalScale
                                                      customInput={TextField}
                                                      InputLabelProps={{
                                                        classes: {
                                                          root: classes.cssLabel,
                                                          focused: classes.cssFocused,
                                                        },
                                                      }}
                                                      InputProps={{
                                                        classes: {
                                                          root: classes.cssOutlinedInput,
                                                          focused: classes.cssFocused,
                                                          notchedOutline: classes.notchedOutline,
                                                        },
                                                        endAdornment: (
                                                          <InputAdornment position="end" style={{ height: "100%" }}>
                                                            <span style={{ color: "inherit" }}>%</span>
                                                            <div
                                                              style={{
                                                                borderLeft: "1px solid #d7d7d7de",
                                                                height: "100%",
                                                                marginLeft: "15px",
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <IconButton
                                                                style={{ marginLeft: "5px" }}
                                                                aria-label="delete discount"
                                                                onClick={(e) => {
                                                                  const updatedItems = [...items];
                                                                  updatedItems[i] = {
                                                                    ...updatedItems[i],
                                                                    discount: "none",
                                                                    discountAbsolute: 0,
                                                                    discountPercent: 0,
                                                                    discountValue: 0,
                                                                  };
                                                                  setItems(updatedItems);
                                                                  setCurrentItem({
                                                                    ...currentItem,
                                                                    discount: "none",
                                                                    discountAbsolute: 0,
                                                                    discountPercent: 0,
                                                                    discountValue: 0,
                                                                  });
                                                                  changeItem(
                                                                    {
                                                                      target: { value: "none", id: "discount" },
                                                                    },
                                                                    currentItem.i,
                                                                    items,
                                                                    setItems
                                                                  );
                                                                }}
                                                              >
                                                                <DeleteForeverIcon fontSize="small" />
                                                              </IconButton>
                                                            </div>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                      onValueChange={({ value: v }) => {
                                                        setCurrentItem({
                                                          ...currentItem,
                                                          discountPercent: v,
                                                        });
                                                        changeItem(
                                                          {
                                                            target: { value: v, id: "discountPercent" },
                                                          },
                                                          currentItem.i,
                                                          items,
                                                          setItems
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        changeItem(
                                                          {
                                                            target: { value: currentItem.discountPercent, id: "discountPercent" },
                                                          },
                                                          currentItem.i,
                                                          items,
                                                          setItems
                                                        );
                                                      }}
                                                    />
                                                  </Grid>
                                                )}

                                                {/* discountedTotal */}
                                                <Grid
                                                  container
                                                  item
                                                  md={6}
                                                  lg={2}
                                                  justifyContent="center"
                                                  alignItems="center"
                                                  style={{
                                                    background: "rgb(240 243 249)",
                                                    flexDirection: "column",
                                                    maxHeight: 54,
                                                    marginTop: 8,
                                                    borderRadius: 3,
                                                    maxWidth: 175,
                                                  }}
                                                >
                                                  <Typography style={{ fontSize: "10px", textAlign: "center", lineHeight: "1.2", marginBottom: "4px" }}>
                                                    Gesamtpreis (netto)
                                                  </Typography>
                                                  <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
                                                  {parseFloat(item.discountedTotal ? item.discountedTotal : 0).toLocaleString("de-DE", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })} €
                                                  </Typography>
                                                </Grid>
                                              </Grid>

                                              {/* Delete Button and "Fertig" Button */}
                                              <Grid container item xs={12} justifyContent="flex-start" alignItems="center" style={{ marginTop: 15, paddingRight: 10 }}>
                                                {/* Leistung löschen Button */}
                                                <Grid item>
                                                  <Button
                                                    size="small"
                                                    style={{ opacity: 0.5, marginRight: 20 }}
                                                    startIcon={<DeleteForeverIcon />}
                                                    onClick={() => {
                                                      const updatedItems = items.filter((_, index) => index !== i);
                                                      setItems(updatedItems);
                                                      setCurrentItem({
                                                        ...currentItem,
                                                        i: false,
                                                      });
                                                    }}
                                                  >
                                                    Löschen
                                                  </Button>
                                                </Grid>

                                                {/* Fertig Button */}
                                                <Grid item>
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    style={{ backgroundColor: "rgb(156, 173, 201)", color: "white" }}
                                                    startIcon={<CheckCircleIcon />}
                                                    onClick={() => {
                                                      setCurrentItem({
                                                        ...currentItem,
                                                        i: false, // Collapse the current accordion
                                                      });
                                                    }}
                                                  >
                                                    Fertig
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </AccordionDetails>
                                        )}
                                      </Accordion>


                                    ))}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container item lg={12} spacing={1}>
                                <Button
                                  disabled={isLoading || disabledEditing || values.type === "cancellation"}
                                  variant="outlined"
                                  size="medium"
                                  color="primary"
                                  style={{ marginLeft: "5px", marginTop: "5px", marginBottom: "10px", textTransform: "none" }}
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => addItem(items, setItems, currentItem, setCurrentItem)}
                                >
                                  Leistung hinzfügen
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    }

                  {/* Global discount */}
                  {selectedClient && values.clientData !== "" && openInvoiceDialogAtom.invoiceId !== "" &&
                   <Box
                   display="flex"
                   flexDirection="column"
                   // padding="25px 0px"
                   className="h-gap--20"
                   >
                   <Grid
                     container
                     item
                     direction="row"
                     alignContent="flex-start"
                     alignItems="flex-start"
                     className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                   >
                       <Grid container item lg={12} spacing={3} >
                          {values.discount === "none" &&
                            <Grid container item lg={4}>

                              <FormControl disabled={isLoading || disabledEditing || values.type === "cancellation"} variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                <InputLabel id="discount">Rabatt auf Gesamtbetrag (netto)</InputLabel>
                                <Select
                                  labelId="discount"
                                  id="discount"
                                  name="discount"
                                  value={values.discount}
                                  onChange={(e) => setValues({ ...values, discount: e.target.value })}
                                  label="Rabatt auf Gesamtrechnung"
                                  fullWidth
                                >

                                  <MenuItem value={"none"}>Kein Rabatt</MenuItem>
                                  <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                  <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          }

                          {values.discount === "absolute" &&

                            <Grid container item lg={4}>
                              <NumericFormat
                                disabled={isLoading}
                                required
                                id="discountAbsolute"
                                label="Rabatt in €"
                                variant="outlined"
                                value={values.discountAbsolute}
                                prefix="€ "
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                valueIsNumericString
                                fixedDecimalScale
                                customInput={TextField}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                  },
                                }}
                                onValueChange={({ value: v }) =>
                                  setValues({
                                    ...values,
                                    discountAbsolute: v
                                  })
                                }

                                InputProps={{
                                  classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                  },
                                  endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                    <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                      <IconButton
                                        style={{ marginLeft: "5px" }}
                                        aria-label="delete discount"
                                        onClick={() => setValues({
                                          ...values,
                                          discount: "none",
                                          discountAbsolute: "0"
                                        })}
                                        disabled={isLoading}
                                      >
                                        <DeleteForeverIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  </InputAdornment>
                                }}
                              />

                            </Grid>
                          }

                          {values.discount === "percent" &&

                            <Grid container item lg={4}>
                              <TextField
                                disabled={isLoading}
                                label="Rabatt in %"
                                id="discountPercent"
                                variant="outlined"
                                value={values.discountPercent}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    discountPercent: e.target.value
                                  })
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                  },
                                  endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                    <span style={{ color: "inherit" }}>%</span>
                                    <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                      <IconButton
                                        style={{ marginLeft: "5px" }}
                                        aria-label="delete discount"
                                        onClick={() => setValues({
                                          ...values,
                                          discount: "none",
                                          discountPercent: 0
                                        })}
                                        disabled={isLoading}
                                      >
                                        <DeleteForeverIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  </InputAdornment>
                                }}

                              />
                            </Grid>

                          }
                        </Grid>

                        {values.type === "reminderRequest" &&
                          <Grid container item lg={4} spacing={2} >
                            <Grid container item >
                              <NumericFormat
                                disabled={isLoading}
                                id="reminderRequestCharge"
                                label="Mahnspesen"
                                variant="outlined"
                                value={values.reminderRequestCharge}
                                prefix="€ "
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                valueIsNumericString
                                fixedDecimalScale
                                customInput={TextField}
                                fullWidth
                                InputLabelProps={{
                                  classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                  },
                                }}
                                onValueChange={({ value: v }) =>
                                  setValues({
                                    ...values,
                                    reminderRequestCharge: v
                                  })
                                }

                                InputProps={{
                                  classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        }

                        {openInvoiceDialogAtom.isEstimate &&
                          <Grid container item xs={12} spacing={2} >
                            <Grid container item >
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={isLoading}
                                    checked={values.showVat}
                                    onChange={() => setValues({ ...values, showVat: !values.showVat })}
                                    name="showVat"
                                    color="primary"
                                  />
                                }
                                label={`USt. auf ${values.type === "estimate" ? "Kostenvoranschlag" : "Angebot"} anzeigen`}
                              />
                            </Grid>
                          </Grid>
                        }
                        {openInvoiceDialogAtom.isEstimate &&
                          <Grid container item xs={12} spacing={2} >
                            <Grid container item >
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={isLoading}
                                    checked={values.showSignature}
                                    onChange={() => setValues({ ...values, showSignature: !values.showSignature })}
                                    name="showSignature"
                                    color="primary"
                                  />
                                }
                                label={`Unterschriftenzeile anzeigen`}
                              />
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </Box>
                  }

                  {/* Additional text */}
                  {(selectedClient && values.clientData !== "" && openInvoiceDialogAtom.invoiceId !== "" && !openInvoiceDialogAtom.isEstimate) &&
                     <Box
                     display="flex"
                     flexDirection="column"
                     // padding="25px 0px"
                     className="h-gap--20"
                     >
                     <Grid
                       container
                       item
                       direction="row"
                       alignContent="flex-start"
                       alignItems="flex-start"
                       className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                     >
                        {!openInvoiceDialogAtom.isEstimate &&
                          <Grid container item xs={12} spacing={2} >
                            <Grid container item >
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={isLoading || disabledEditing}
                                    checked={values.showAdditionalText}
                                    onChange={() => setValues({ ...values, showAdditionalText: !values.showAdditionalText })}
                                    name="showAdditionalText"
                                    color="primary"
                                  />
                                }
                                label={`Zusatztext`}
                              />
                            </Grid>
                          </Grid>
                        }
                        {(!openInvoiceDialogAtom.isEstimate && values.showAdditionalText) &&
                          <Grid item xs={12} style={{ marginBottom: "20px" }}>
                            <Chip
                              size="small"
                              label="Textvorlagen:"
                              style={{ marginBottom: 10, borderRadius: 2, marginRight: 5 }}
                            />
                            <Chip
                              size="small"
                              label="Reverse Charge"
                              variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                              disabled={isLoading || disabledEditing}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  additionalText: "Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)."
                                });
                                // Focus the text field after setting the value
                                textFieldRef.current.focus();
                              }}
                              onDelete={() => {
                                setValues({
                                  ...values,
                                  additionalText: values.additionalText === "" ? "Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)." : values.additionalText += " Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)."
                                });
                                textFieldRef.current.focus();
                              }}
                              deleteIcon={<AddCircleIcon />}
                            />
                            <Chip
                              size="small"
                              label="Änderung Bankverbindung"
                              variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                              disabled={isLoading || disabledEditing}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  additionalText: "Bitte beachten Sie, dass sich unsere Bankverbindung geändert hat."
                                });
                                // Focus the text field after setting the value
                                textFieldRef.current.focus();
                              }}
                              onDelete={() => {
                                setValues({
                                  ...values,
                                  additionalText: values.additionalText === "" ? "Bitte beachten Sie, dass sich unsere Bankverbindung geändert hat." : values.additionalText += " Bitte beachten Sie, dass sich unsere Bankverbindung geändert hat."
                                });
                                textFieldRef.current.focus();
                              }}
                              deleteIcon={<AddCircleIcon />}
                            />
                            <Chip
                              size="small"
                              label="Zahlung nach Erhalt"
                              variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                              disabled={isLoading || disabledEditing}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  additionalText: "Wir bitten um Zahlung nach Erhalt, ohne Abzug."
                                });
                                // Focus the text field after setting the value
                                textFieldRef.current.focus();
                              }}
                              onDelete={() => {
                                setValues({
                                  ...values,
                                  additionalText: values.additionalText === "" ? "Wir bitten um Zahlung nach Erhalt, ohne Abzug." : values.additionalText += " Wir bitten um Zahlung nach Erhalt, ohne Abzug."
                                });
                                textFieldRef.current.focus();
                              }}
                              deleteIcon={<AddCircleIcon />}
                            />

                            <Chip
                              size="small"
                              label="Rechnungsnummer als Zahlungsreferenz"
                              variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                              disabled={isLoading || disabledEditing}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  additionalText: "Bitte die Rechnungsnummer als Zahlungsreferenz anführen."
                                });
                                // Focus the text field after setting the value
                                textFieldRef.current.focus();
                              }}
                              onDelete={() => {
                                setValues({
                                  ...values,
                                  additionalText: values.additionalText === "" ? "Bitte die Rechnungsnummer als Zahlungsreferenz anführen." : values.additionalText += " Bitte die Rechnungsnummer als Zahlungsreferenz anführen."
                                });
                                textFieldRef.current.focus();
                              }}
                              deleteIcon={<AddCircleIcon />}
                            />

                            <TextField
                              multiline
                              autoComplete="off"
                              placeholder="Text ..."
                              disabled={isLoading || disabledEditing}
                              defaultValue={values.additionalText}
                              value={values.additionalText}
                              id="additionalText"
                              // label={`Text`}
                              variant="outlined"
                              type="textarea"
                              onChange={(e) => setValues({ ...values, additionalText: e.target.value })}
                              fullWidth
                              inputRef={textFieldRef}
                            />
                          </Grid>
                        }
                      </Grid>
                    </Box>
                  }

                  {/* {(selectedClient && !openInvoiceDialogAtom.isEstimate) &&
                    <Box
                      display="flex"
                      flexDirection="column"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >
                      </Grid>
                    </Box>
                  } */}
                </Box>
                }

                {values.loading &&
                  <div className="center">
                    <CircularProgress />
                  </div>
                }
              </Container>
            </Container>
          </div>

          {(!isLoading && !values.loading && openInvoiceDialogAtom.invoiceId !== "") &&
            <InvoiceLivePreview
              openPdfPreview={open} // Set true to open email send
              setOpen={setOpen}
              sendMode={sendMode} // Set true to open email send
              client={selectedClient}
              items={items}
              clientNr={selectedClient && JSON.parse(selectedClient.clientNumber).map((item, i) => (item.value))}
              invoiceId={values.id}
              invoiceNr={JSON.parse(values.invoiceNr).map((item, i) => (item.value))}
              invoiceDate={new Date(values.invoiceDate)}
              invoiceDueDate={new Date(values.dueDate)}
              invoiceSubtotal={values.subtotal}
              invoiceDiscount={values.discount}
              invoiceDiscountAbsolute={values.discountAbsolute}
              invoiceDiscountPercent={values.discountPercent}
              invoiceDiscountValue={values.discountValue}
              invoiceDiscountedSubtotal={values.discountedSubtotal}
              invoiceVatValues={JSON.parse(values.vatValues)}
              invoiceTotal={values.total}
              headline={values.headline}
              text={values.text}
              loading={values.loading}
              directDownload={false}
              invoiceValues={values}
              selectedClient={selectedClient}
              userId={userId}
              setInvoicesAtom={setInvoicesAtom}
              setInvoicesFilteredAtom={setInvoicesFilteredAtom}
              sendState={values.sendState.state}
              reminderRequestCharge={values.reminderRequestCharge}
              isEstimate={openInvoiceDialogAtom.isEstimate}
              type={"invoicerecurring"}
              showVat={values.showVat}
              showSignature={values.showSignature}
              showAdditionalText={values.showAdditionalText}
              additionalText={values.additionalText}
              recurring={true}
            />
          }
        </div>


        {/* <Backdrop
          // When invoice, etc. is scheduled (prevents editing)
          className={classes.backdrop}
          open={values.sendState === "scheduled" ? true : false}
        /> */}
      </React.Fragment>
    </React.Fragment>
  );
};

export default InvoicesRecurringsDIALOG;
