import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CustomEditor = ({ details = "", onUpdateDetails, disabled }) => {
  // Convert the initial HTML to Draft.js content
  const contentBlock = htmlToDraft(details);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks,
    contentBlock.entityMap
  );
  const initialEditorState = EditorState.createWithContent(contentState);

  const [editorState, setEditorState] = useState(initialEditorState);

  return (
    <div
      style={{
        border: "2px solid #f0f3ff",
        borderRadius: "4px",
        padding: "10px",
        width: "100%",
      }}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={(newState) => {
          setEditorState(newState);

          // Update the parent only if necessary
          const newDetails = draftToHtml(
            convertToRaw(newState.getCurrentContent())
          );
          if (newDetails !== details) {
            onUpdateDetails(newDetails);
          }
        }}
        toolbar={{
          options: ["inline", "list"],
          inline: { options: ["bold"] },
          list: { options: ["unordered", "ordered"] },
        }}
        readOnly={disabled}
        placeholder="Details beschreiben ..."
        wrapperClassName="editor-wrapper"
        editorClassName="editor editor-invoice-details"
      />
    </div>
  );
};

export default CustomEditor;
