import React, { useContext, useState } from "react";

// IMPORT hooks & context
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import loggedInAtomState from "../../_atoms/loggedInAtom";
import settingsAtomState from "../../_atoms/settingsAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import expensesAtomState from "../../_atoms/expensesAtom";

// IMPORT functions
import { VALIDATE } from '../../_functions/VALIDATE';
import { SET_VALUES } from '../../_functions/SET_VALUES';
import { ERROR } from '../../_functions/ERROR';

// IMPORT components
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import Logo from "../../shared/components/UIElements/Logo";

import Copyright from "../../shared/components/UIElements/Copyright";
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import { Typography, Box, FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Alert from "@material-ui/lab/Alert";
import Fade from '@material-ui/core/Fade';


// IMPORT icons MATERIAL UI
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';

// IMPORT theme MATERIAL UI and custom scss
import { useTheme } from '@material-ui/core/styles';

// IMPORT functions
import { DB_PATCH_SETTINGS } from './../../_functions/DB_SETTINGS';
import { INVOICE_THEME } from "./../../invoices/components/preview/defaultDataThemes"

const Auth = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);
  const [, setClientsAtom] = useRecoilState(clientsAtomState);
  const [, setLoggedInAtom] = useRecoilState(loggedInAtomState);

  // LOCAL STATE
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.email.error"),
    },
    password: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.password.error"),
    },
  });

  /* 
    FUNCTIONS
  */
  const handleChange = (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const validate = (field) => {
    VALIDATE([
      "email",
      "password"
    ],
      field, values, setValues);
  };

  const authSubmitHandler = async (e) => {
    e.preventDefault();

    if (!values.email.error && !values.password.error) {
      try {
        const resUser = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/users/login`,
          "POST",
          JSON.stringify({
            email: values.email.state,
            password: values.password.state,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        auth.login(
          resUser.userId,
          resUser.token,
        );

        setUserAtom({
          ...userAtom,
          data: resUser.data
        });

        // GET settings
        try {
          const resSettings = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/settings/user/${resUser.userId}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + resUser.token,
            }
          );

          setSettingsAtom(resSettings.settings[0]);

          // GET theme(s)
          if (resSettings.settings[0].invoicesDefaultTheme === "unset") {
            // console.log(resSettings.settings[0].invoicesDefaultTheme === "unset")
            const formData = new FormData();
            formData.append("properties", JSON.stringify(INVOICE_THEME("classic")));
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL_API}/themes`,
                "POST",
                formData,
                {
                  Authorization: "Bearer " + resUser.token,
                }
              )
                .then((res) => {
                  setSettingsAtom({ ...resSettings.settings[0], invoicesDefaultTheme: res.theme._id });
                  DB_PATCH_SETTINGS({ ...resSettings.settings[0], invoicesDefaultTheme: res.theme._id }, { token: resUser.token }, sendRequest, enqueueSnackbar, t, false);
                })
            } catch (err) {
              console.log(err)
            }
          }
        } catch (err) { }

        // GET clients
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients/user/all/${resUser.userId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + resUser.token,
            }
          )
          // NO ERROR
          .then((res) => setClientsAtom(res.clients))
        } catch (err) { 
          // ERROR
          console.log(err)
        }

        // GET invoices
        // try {
        //   await sendRequest(
        //     `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${resUser.userId}`,
        //     "GET",
        //     null,
        //     {
        //       Authorization: "Bearer " + resUser.token,
        //     }
        //   )
        //   // NO ERROR
        //   .then((res) => setInvoicesAtom(res.invoices))
        // } catch (err) { 
        //   // ERROR
        //   console.log(err)
        // }

        // GET expenses
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/expenses/user/${resUser.userId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + resUser.token,
            }
          )
          // NO ERROR
          .then((res) => setExpensesAtom(res.expenses))
        } catch (err) { 
          // ERROR
          // console.log(err)
        }

        setLoggedInAtom(true);
        navigate("/");
      } catch (err) { }
    }
  };

  return (
    <Container maxWidth={false} disableGutters className="bg--2">
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          padding="50px"
          className="h-gap--50"
        >

          <Logo color="bright" />

          <Fade in={true}>
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className="h-gap--30 h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1"
          >
            <form onSubmit={authSubmitHandler}>
              <Grid container item spacing={2}>
                <Grid container item direction="column" alignItems="center">
                  <div className="icon-wrapper--lg" style={{ background: theme.palette.secondary.main }}>
                    <LockIcon size="large" />
                  </div>

                  <Typography component="h1" variant="h5">{t("PAGE_LOGIN.h1")}</Typography>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      autoComplete="off"
                      id="email"
                      label={`${t("FIELDS.email.label")}`}
                      variant="outlined"
                      type="email"
                      onChange={handleChange}
                      onBlur={() => validate("email")}
                      error={values.email.error}
                      helperText={values.email.error ? values.email.errorMsg : ""}
                      fullWidth
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel required htmlFor="password" error={values.password.error}>
                        {t("FIELDS.password.label")}
                      </InputLabel>

                      <OutlinedInput
                        id="password"
                        autoComplete="new-password"
                        type={showPassword ? "text" : "password"}
                        value={values.password.state}
                        onChange={handleChange}
                        error={values.password.error}
                        onBlur={() => validate("password")}
                        disabled={isLoading}
                        labelWidth={83}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={t("BUTTONS.AL_password_visibilty")}
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText
                        error={values.password.error}
                        id="form-helper-text-password"
                      >
                        {values.password.error ? values.password.errorMsg : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                {error &&
                  <Grid item xs={12}>
                    <Alert severity="error">{ERROR(t, error)}
                    </Alert>
                  </Grid>
                }

                <Grid item xs={12}>
                  <ButtonLoading
                    loading={isLoading}
                    disabled={isLoading}
                    type="submit"
                    onClick={() => validate("all")}
                    fullWidth
                    size="large"
                    className="button--loading"
                    endIcon={<ExitToAppIcon />}
                  >
                    {t("BUTTONS.login")}
                  </ButtonLoading>
                </Grid>
              </Grid>
            </form>

            <Grid container item justifyContent="space-between">
              <Typography component="div" variant="subtitle2">
                <Link
                  href="/forgot-password"
                  variant="subtitle2"
                  style={{ fontWeight: "300" }}
                >
                  {t("PAGE_LOGIN.forgot_password")}
                </Link>
              </Typography>

              <Typography component="div" variant="subtitle2">
                <Link
                  href="/register"
                  variant="subtitle2"
                  style={{ fontWeight: "300" }}
                >
                  {t("PAGE_LOGIN.link_register")}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          </Fade>

          <Copyright color="bright" />
        </Box>
      </Container>
    </Container>
  );
};

export default Auth;
