import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Paper } from "@material-ui/core";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function InvoicePdfPreview({ pdfString }) {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderPages = () => {
    let pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <Paper
          key={i}
          elevation={0}
          style={{
            margin: "20px auto",
            width: "fit-content",
            overflow: "hidden",
            boxShadow: "0px 3px 3px -2px #545ea51a, 0px 3px 4px 0px #545ea51a, 0px 1px 8px 0px #545ea51a"
          }}
        >
          <Page pageNumber={i} scale={2} />
        </Paper>
      );
    }
    return pages;
  };

  return (
    <div className="invoice-pdf-preview" style={{maxWidth: 1000, margin: "0 auto"}}>
      <Document
        file={`data:application/pdf;base64,${pdfString}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {renderPages()}
      </Document>
    </div>
  );
}
